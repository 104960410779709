import React from 'react';
import usersUrl from '@/users/infrastructure/usersUrl';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import styles from './footer.scss';
import config from '@/Framework/config';
import BookDemoButton from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import footerLinkStyles from '@/ui/shared/components/Landing/StickyFooter/FooterLink/footerLink.scss';

export const footerConfig = (tenant) => ({
  navigation: [
    {
      path: finsightWebUrl.getComplianceUrl(),
      title: 'Compliance',
      shouldOpenInNewTab: true,
    },
    {
      path: finsightWebUrl.getPrivacyPolicyUrl(),
      title: 'Privacy',
      shouldOpenInNewTab: true,
    },
    {
      path: finsightWebUrl.getSecurityPolicyUrl(),
      title: 'Security',
      shouldOpenInNewTab: true,
    },
    {
      path: finsightWebUrl.getTermsOfUseUrl(),
      title: 'Terms',
      shouldOpenInNewTab: true,
    },
  ],
  content: [
    {
      key: 'company',
      title: 'Company',
      collection: [
        {
          title: 'About',
          path: finsightWebUrl.getAboutUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Careers',
          path: finsightWebUrl.getCareersUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Contact',
          path: finsightWebUrl.getContactUsUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Solutions',
          path: finsightWebUrl.getOurProductsUrl(),
          shouldOpenInNewTab: true,
        },
      ],
    },
    {
      key: 'dealroadshow',
      title: 'Deal Roadshow',
      collection: [
        {
          itemCallback: () => (
            <BookDemoButton
              tenant={ tenant }
              className={ footerLinkStyles.link }
              buttonComponent="a"
            />
          ),
        },
        { title: 'Institutional Roadshows', path: '/' },
        { title: 'Retail Roadshows', path: '/retail-roadshows' },
      ],
    },
    {
      key: 'products',
      title: 'Products',
      collection: [
        {
          title: 'Deal Roadshow',
          path: drsUrl.getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'DealVDR',
          path: dataroomUrl(config.tenant.tenantDealvdr.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Evercall',
          path: evercallUrl.getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Finsight.com',
          path: finsightWebUrl.getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'InvestorSet',
          path: dataroomUrl(config.tenant.tenantInvestorSet.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'VeriSend',
          path: dataroomUrl(config.tenant.tenantVeriSend.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: '17g5.com',
          path: dataroomUrl(config.tenant.tenant17g5.code).getUrl(),
          shouldOpenInNewTab: true,
        },
      ],
    },
    {
      key: 'resources',
      title: 'Resources',
      collection: [
        {
          title: 'Log In',
          path: usersUrl.getLoginUrl(usersUrl.getCallbackUrl(dmPortalUrl.getUrl('/roadshow')), tenant),
        },
        {
          title: 'Register',
          path: usersUrl.getRegisterUrl(usersUrl.getCallbackUrl(dmPortalUrl.getUrl('/roadshow')), tenant),
        },
      ],
    },
    {
      key: 'sales',
      title: 'Sales & Support',
      collection: [
        { title: 'Americas', phoneNumber: '+1 888 997 6610 ' },
        { title: 'APAC', phoneNumber: '+852 3018 1600' },
        { title: 'EMEA', phoneNumber: '+44 80 8178 7364' },
        {
          title: config.tenant.dealroadshow.supportEmail,
          path: `mailto:${ config.tenant.dealroadshow.supportEmail }?subject=Deal Roadshow Sales %26 Support Request`,
          linkClassName: styles.mailLink,
        },
      ],
    },
  ],
});
