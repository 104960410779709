import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useRouter } from 'next/router';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import usersUrl from '@/users/infrastructure/usersUrl';
import config from '@/Framework/config';
import Favicon from '@/dealroadshow/ui/common/Favicon';
import { Icon, IconType } from '@dealroadshow/uikit';
import CookiesBanner from '@/ui/shared/components/Landing/CookiesBanner';
import Header from '@/ui/shared/components/Landing/Legacy/Sections/Header';
import Footer from '../Footer/Footer';
import { useCustomizationContext } from '@/dealroadshow/application/Viewer/CustomizationContext';

import logo from '../../../assets/dealroadshowLandingLogo.svg';
import { Tenant } from '@/Framework/Tenant/vo/types/Tenant';
import { isScreenXs } from '@/ui/shared/styles/screen/screen';
import headerStyles from '@/ui/shared/components/Landing/Legacy/Sections/Header/header.scss';
import stickyFooterStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';
import styles from './layout.scss';

interface IProps {
  children: React.ReactNode,
}

const headerMenu = [
  {
    key: 'institutional',
    title: 'Institutional',
    path: '/',
    isActive: () => window.location.pathname === '/' || window.location.pathname.includes('/login/investor'),
  },
  {
    key: 'retail',
    title: 'Retail Roadshows',
    path: '/retail-roadshows',
  },
];

const Layout = (props: IProps) => {
  const router = useRouter();
  const entryCode = router.query.entryCode as string;
  const [isMobile, setIsMobile] = useState(isScreenXs());
  const tenant = config.tenant.dealroadshow.code as Tenant;
  const {
    colorSettings = {},
    getViewerCustomSettings,
    isPending,
    isCustomizationDisclaimerEnabled,
  } = useCustomizationContext();

  useEffect(() => {
    if (entryCode) {
      getViewerCustomSettings(entryCode);
    }
  }, []);

  const loginLinkCls = cn(
    headerStyles.menuLink,
    headerStyles.menuIconLink,
    headerStyles.menuLoginButton,
    headerStyles.tenantDealroadshow,
  );
  const loginIconType = isMobile ? IconType.userLock : IconType.lock;

  useEffect(() => {
    const handleResize = () => setIsMobile(isScreenXs());
    window.addEventListener('resize', throttle(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const shouldRenderHeader = !entryCode || (!isPending && !colorSettings?.viewerDisclaimerCustomizationEnabled);

  return (
    <>
      <Favicon />
      { shouldRenderHeader && (
        <Header
          isNext
          logo={ logo }
          menu={ headerMenu }
          tenant={ tenant }
          loginButton={ () => (
            <a
              href={ usersUrl.getLoginUrl(
                encodeURIComponent(dmPortalUrl.getUrl('/roadshow')), tenant,
              ) }
              className={ loginLinkCls }
            >
              <Icon
                className={ headerStyles.menuLoginIcon }
                type={ loginIconType }
              />
              Deal Manager
            </a>
          ) }
        />
      ) }
      <div
        className={
        cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter, {
          [styles.pageContainerCustom]: isCustomizationDisclaimerEnabled && entryCode,
        })
      }
      >
        { props.children }
      </div>
      <Footer tenant={ tenant } />
      <CookiesBanner />
    </>
  );
};

export default Layout;
